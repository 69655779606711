import React, { createContext, useContext, useState } from "react";

type AppContextType = {
  useUppy: boolean;
  setUseUppy: (value: boolean) => void;
  useIndexedDb: boolean;
  setUseIndexedDb: (value: boolean) => void;
};

const { USE_UPPY = false, USE_INDEXED_DB = false } = window.MLBBest.envVariables || {};

const AppContext = createContext<AppContextType | undefined>(undefined);

const AppProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [useUppy, setUseUppy] = useState<boolean>(USE_UPPY);
  const [useIndexedDb, setUseIndexedDb] = useState<boolean>(USE_INDEXED_DB);

  return (
    <AppContext.Provider
      value={{
        useUppy,
        setUseUppy,
        useIndexedDb,
        setUseIndexedDb,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useApp = (): AppContextType => {
  const rolesContext: AppContextType = useContext<AppContextType>(AppContext);
  if (rolesContext === undefined) {
    throw new Error(`useApp must be used within a AppProvider`);
  }
  return rolesContext;
};

export { AppContext, AppProvider, useApp };
